import Container_ScotchDoubleVerticalAreaComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/ScotchDoubleVerticalArea/ScotchDoubleVerticalArea.skin';


const Container_ScotchDoubleVerticalArea = {
  component: Container_ScotchDoubleVerticalAreaComponent
};


export const components = {
  ['Container_ScotchDoubleVerticalArea']: Container_ScotchDoubleVerticalArea
};

